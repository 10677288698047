import _ from 'lodash';
import { Checkbox } from 'react95';
import { ACTION_STAGE_MAP, CURRENT_TAX_YEAR, TASK_EXPORT_FIELDS } from '../global/Environment';

export const getStageDisplayName = (stage) => {
  switch (stage) {
    case 'Not Started':
      return 'Unlocked';
    case 'eng escalation':
      return 'Eng Escalation';
    case 'user escalation':
      return 'User Escalation';
    default:
      return stage;
  }
};

/**
 * Highlights the matching parts of the text based on the provided search terms.
 * There can be multiple search terms to account for searching "First Last,"
 * which are two separate columns in the table, so we check for matches on each term.
 *
 * @param {string | null} text - The text to be highlighted.
 * @param {string | null} searchFilter - Array of lowercased terms to highlight within the text.
 * @returns {JSX.Element} - A React component with highlighted matching parts.
 */
export const highlightText = (text, searchFilter) => {
  if (!searchFilter || !text) {
    return <span>{text}</span>;
  }

  const lowerSearchTerms = _.words(searchFilter.toLowerCase());
  const lowerFieldValue = text.toLowerCase();
  const isSearchTermMatch = lowerSearchTerms.some((term) => lowerFieldValue.includes(term));

  // If the search term is not found, return early to avoid unnecessary processing
  if (!isSearchTermMatch) {
    return <span>{text}</span>;
  }

  // Create a case-insensitive regex pattern for each search term, escaping special characters
  const escapedTerms = lowerSearchTerms.map(_.escapeRegExp);
  const regex = new RegExp(`(${escapedTerms.join('|')})`, 'gi');
  // Split the text into parts based on the search terms
  const parts = text.split(regex);

  return (
    <span>
      {parts.map((part, index) =>
        lowerSearchTerms.includes(part.toLowerCase()) ? (
          <span key={index} style={{ backgroundColor: 'yellow' }}>
            {part}
          </span>
        ) : (
          <span key={index}>{part}</span>
        )
      )}
    </span>
  );
};

/**
 * Gets the display value of a task field and highlights matching search terms if applicable.
 *
 * @param {string} field - The field name of the task.
 * @param {Object} task - The task object containing various fields.
 * @param {Object} qdeckUserEmailNameMap - A map of user emails to names.
 * @param {string} searchFilter - The search term used for highlighting.
 * @returns {JSX.Element|string} - The display value of the task field, potentially highlighted.
 */
export const getTaskFieldDisplayVal = (field, task, qdeckUserEmailNameMap, searchFilter) => {
  const fieldsWithNestedObjects = [
    'healthcare_taxpayer',
    'healthcare_spouse',
    'healthcare_dependents',
    'multistate_residency',
    'multistate_address',
    'multistate_income',
    'multistate_expenses'
  ];

  if (fieldsWithNestedObjects.includes(field)) {
    const [colName, nestedField] = field.split('_');
    const fieldVal = _.get(task, [colName, nestedField]);
    return fieldVal ? JSON.stringify(fieldVal, null, 2) : '';
  }

  if (field === 'stage') {
    return getStageDisplayName(task[field]);
  }

  if (['updatedAt', 'last_upload', 'last_stage_change'].includes(field) && task[field]) {
    return new Date(task[field]).toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles',
      timeZoneName: 'short'
    });
  }

  if (field === 'assignee' && task[field]) {
    return qdeckUserEmailNameMap[task[field]];
  }

  if ((field === 'phone' || field === 'email') && task.user) {
    const fieldValue = task.user[field];

    return highlightText(fieldValue, searchFilter);
  }

  if (field === 'federal_amount' && task.amounts) {
    return task.amounts[field];
  }

  if (field === 'multistate_amount' && task.amounts) {
    return JSON.stringify(task.amounts?.multistate);
  }

  if (field === 'new' && task.ops_status?.new) {
    return (
      <div className='new-cell'>
        <Checkbox checked disabled />
      </div>
    );
  }

  const fieldsToHighlight = ['first_name', 'last_name', 'user_id', 'ssn'];
  if (searchFilter != null && task[field] != null && fieldsToHighlight.includes(field)) {
    const fieldValue = task[field].toString();
    return highlightText(fieldValue, searchFilter);
  }

  return task[field];
};

export const formatTableData = (tasks) => {
  const taskData = tasks.map((task) =>
    TASK_EXPORT_FIELDS.map((field) => {
      if (field === 'phone' || field === 'email') {
        return _.get(task, ['user', field]) || '';
      }

      if (!task[field]) {
        return '';
      }

      if (_.isObject(task[field])) {
        const str = JSON.stringify(task[field]);
        return str.replace(/"/g, '""');
      }

      return task[field];
    })
  );

  const csvData = [TASK_EXPORT_FIELDS, ...taskData];
  return csvData;
};

export const getAvailableActions = (tab) => {
  switch (tab) {
    case 1:
      return ['Drake Upload', 'Unlock user', 'Move to Tax Expert Esc', 'Move to eng esc', 'DO NOT FILE'];
    case 2:
      return ['Move to Ops Review', 'Move to Final Review', 'Filed with IRS', 'Accepted by IRS'];
    default:
      return ['User Escalation', 'Confirm Amounts', 'Filed with IRS', 'Accepted by IRS'];
  }
};

export const isActionDisabled = (action, task, user) => {
  if (task.year !== CURRENT_TAX_YEAR) {
    return true;
  }

  if (!task.assignee || task.assignee !== user.email) {
    return true;
  }

  const blocked = _.get(task, ['ops_status', 'blocked']) || false;
  const emailOutbox = _.get(task, ['ops_status', 'emailOutbox']) || false;
  if (blocked || emailOutbox) {
    return true;
  }

  return ACTION_STAGE_MAP[action] ? !ACTION_STAGE_MAP[action].includes(task.stage.toLowerCase()) : true;
};

export const getActionConfirmationMsg = (action) => {
  switch (action) {
    case 'Upload Tax Data':
    case 'Upload Deposit / Withdrawal Forms':
    case 'Unlock user':
    case 'Move to Tax Expert Esc':
    case 'Move to Ops Review':
    case 'Move to Final Review':
    case 'Move to eng esc':
      return action;

    case 'Filed with IRS':
    case 'Accepted by IRS':
    case 'DO NOT FILE':
      return `Move to ${action}`;

    case 'Confirm Amounts':
      return 'Move to Confirm Amounts and notify user';

    case 'Confirm Amounts (no text/email)':
      return 'Move to Confirm Amounts without notifying user';

    case 'Update amounts':
      return 'Run Drake amounts job';

    case 'blocked':
      return 'Update substage to Blocked and email user';

    case 'emailOutbox':
      return 'Update substage to Email Outbox';

    case 'Send User Message':
      return 'Are you sure you want to send this user a message';

    default:
      return '';
  }
};

export const formatActivityLog = (records, qdeckUserEmailNameMap) => {
  const formattedRecords = records.map((record) => {
    const utcTimestamp = new Date(record.createdAt);
    const date = utcTimestamp.toLocaleDateString('en-US', {
      timeZone: 'America/Los_Angeles',
      dateStyle: 'medium'
    });
    const time = utcTimestamp.toLocaleTimeString('en-US', {
      timeZone: 'America/Los_Angeles',
      timeStyle: 'long'
    });

    let text = record.activity;
    if (text.startsWith('<br>')) {
      const updateSource = qdeckUserEmailNameMap[record.source] || record.source;
      const prefix = `<b>${updateSource}</b> updated the following fields:`;
      text = prefix + text;
    }

    return {
      date,
      time,
      activity: text
    };
  });

  return _.groupBy(formattedRecords, 'date');
};

export const formatComments = (records, qdeckUserEmailNameMap) => {
  return records.map((record) => {
    const utcTimestamp = new Date(record.createdAt);

    return {
      ...record,
      authorName: qdeckUserEmailNameMap[record.authorEmail] || record.authorName,
      createdAt: utcTimestamp.toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
        dateStyle: 'short',
        timeStyle: 'long'
      })
    };
  });
};

export const formatAskLog = (records, qdeckUserEmailNameMap) => {
  const sortedMessages = _.chain(records)
    .orderBy((r) => new Date(r.time), 'desc')
    .slice(-200)
    .value();

  return sortedMessages.map((record) => {
    const utcTimestamp = new Date(record.time);

    return {
      ...record,
      authorName: qdeckUserEmailNameMap[record.authorEmail] || record.authorName,
      time: utcTimestamp.toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
        dateStyle: 'short',
        timeStyle: 'long'
      })
    };
  });
};

export const formatUserEscLog = (records) => {
  const formattedRecords = records.map((record) => {
    const utcTimestamp = new Date(record.createdAt);
    const date = utcTimestamp.toLocaleDateString('en-US', {
      timeZone: 'America/Los_Angeles',
      dateStyle: 'medium'
    });
    const time = utcTimestamp.toLocaleTimeString('en-US', {
      timeZone: 'America/Los_Angeles',
      timeStyle: 'long'
    });

    return {
      ...record,
      date,
      time
    };
  });

  // Group records by date and ensure all records for each date are sorted by time (descending)
  const groupedRecords = _.mapValues(_.groupBy(formattedRecords, 'date'), (records) =>
    _.orderBy(records, 'time', 'desc')
  );

  return groupedRecords;
};

/**
 * Checks if the current user is an engineer.
 *
 * @param {string} email - The email of the current user.
 * @param {Object[]} users - An array of user objects.
 * @returns {boolean} - True if the user is an engineer, false otherwise
 */
export const isCurrentUserEng = (email, users) => _.some(users, { email, eng: 1 });

/**
 * Checks if the current user is an admin.
 *
 * @param {string} email - The email of the current user.
 * @param {Object[]} users - An array of user objects.
 * @returns {boolean} - True if the user is an admin, false otherwise
 */
export const isCurrentUserAdmin = (email, users) => _.some(users, { email, admin: 1 });

export const prepareUserDropdownList = (qdeckUserEmailNameMap, userFilter = (email) => true) =>
  _(qdeckUserEmailNameMap)
    .entries()
    .filter(([email, _]) => userFilter(email))
    .map(([email, name]) => (email === 'placeholder' ? { label: '', value: '' } : { label: name, value: email }))
    .sortBy(({ label }) => label)
    .value();
